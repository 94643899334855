.card {
  --borderWidth: 1px;
  position: relative;
  transition: all 300ms ease;
  border: 2px solid rgba(0,0,0, 0.0);
}

.card:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(90deg, #F7B500, #B620E0, #32C5FF);
  border-radius: 20px;
  z-index: -1;
  transition: all 300ms ease;
  opacity: .7;
  -webkit-filter: blur(3px);
}

.card:hover::after {
  opacity: 1;
} 