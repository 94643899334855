.slider-slide {
  overflow: hidden;
}

.slider {
  width: none;
  margin: 0 8px 0
}

@font-face {
  font-family: GothamRounded;
  src: url('./GothamRounded-Medium.otf') format('truetype');
}

h1 {
  font-family: GothamRounded!important;
}